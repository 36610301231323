// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar/navBar"
import ReactMarkdown from 'react-markdown'

function AboutUsPage() {
  const { strapiAboutUs } = useStaticQuery(graphql`
  {
    strapiAboutUs {
      description {
        data {
          description
        }
      }
    }
  }`)

  return (
    <Layout>
      <Seo title="About Us" />
      <NavBar />
    <ReactMarkdown>{strapiAboutUs.description.data.description}</ReactMarkdown>
    </Layout>
  )
} 

export default AboutUsPage
